const state = {
  slogans: [
    {
      no: 4,
      defs: [
        {
          label: '定義',
          desc: [
            '上司からの指示のもと、日常の定型的業務を行いながら仕事を身につける職位',
            '所属部署の方針・目標のもと、上司の助言を踏まえ、一定の習熟を必要とする担当職務を行う職位',
            '所属部署における業務上の責任を持ち、所属部署の目標達成やプロジェクトの遂行及びリーダーとしてスタッフの指導・育成を担う職位',
            '経営戦略策定に参画し、経営方針に基づいた部門の目標達成の責任を担う。また担当部署を統括し、人事に関する一定の権限を有する職位'
          ]
        }
      ]
    },
    {
      no: 5,
      defs: [
        {
          label: '定義',
          desc: [
            '上司からの指示のもと、日常の定型的業務を行いながら仕事を身につける職位',
            '所属部署の方針・目標のもと、上司の助言を踏まえ、一定の習熟を必要とする担当職務を行う職位',
            '所属部署における業務上の責任を持ち、所属部署の目標達成やプロジェクトの遂行及びリーダーとしてスタッフの指導・育成を担う職位',
            '所属部門の戦略策定に参画し、部門の目標達成を担う。またリーダー以下をまとめプロジェクトの達成を実現する職位',
            '全社的な戦略策定に参画し、経営方針に基づいた目標達成の責任を担う。また担当部門を統括し、人事に関する責任を持つ職位'
          ]
        }
      ]
    },
    {
      no: 6,
      defs: [
        {
          label: '定義',
          desc: [
            '上司からの指示のもと、日常の定型的業務を行いながら仕事を身につける職位',
            '所属部署の方針・目標のもと、上司の助言を踏まえ、一定の習熟を必要とする担当職務を行う職位',
            '所属部署の方針・目標のもと、熟練を必要とする業務を担う。また後輩スタッフの模範となる職位',
            '所属部署における業務上の責任を持ち、所属部署の目標達成やプロジェクトの遂行及びリーダーとしてスタッフの指導・育成を担う職位',
            '所属部門の戦略策定に参画し、部門の目標達成を担う。またリーダー以下をまとめプロジェクトの達成を実現する職位',
            '全社的な戦略策定に参画し、経営方針に基づいた目標達成の責任を担う。また担当部門を統括し、人事に関する責任を持つ職位'
          ]
        }
      ]
    },
    {
      no: 7,
      defs: [
        {
          label: '定義',
          desc: [
            '上司からの指示のもと、日常の定型的業務を行いながら仕事を身につける職位',
            '所属部署の方針・目標のもと、上司の助言を踏まえ、一定の習熟を必要とする担当職務を行う職位',
            '所属部署の方針・目標のもと、熟練を必要とする業務を担う。また後輩スタッフの模範となる職位',
            '担当業務やプロジェクトに関する実務面の責任を持ち、リーダーを補助しながら目標を達成する職位',
            '所属部署における業務上の責任を持ち、所属部署の目標達成やプロジェクトの遂行及びリーダーとしてスタッフの指導・育成を担う職位',
            '所属部門の戦略策定に参画し、部門の目標達成を担う。またリーダー以下をまとめプロジェクトの達成を実現する職位',
            '全社的な戦略策定に参画し、経営方針に基づいた目標達成の責任を担う。また担当部門を統括し、人事に関する責任を持つ職位'
          ]
        }
      ]
    }
  ],
  slogansSP: {
    defs: [
      {
        label: '定義',
        desc: [
          '広範囲における専門的知識と経験を有し、困難な技術的、専門的業務を遂行する。また企画判断や研究調査をなす職位',
          '専門的知識及び相当の経験を有し、非定型的な業務や複雑な判断を必要とする業務を遂行する職位'
        ]
      }
    ]
  },
  classDefs: [
    {
      no: 4,
      label: ['スタッフ', 'ミドルスタッフ', 'リーダー', 'マネージャー'],
      labelSP: ['エキスパート', 'スペシャリスト'],
      point: [20, 40, 60, 80],
      lub: [0, 0, 0, 0],
      lvmax: [60, 60, 60, 60],
      cop: [50, 50, 50, 50],
      gousa: [[0, 0], [0, 0], [0, 0], [0, 0]],
      raise: {
        SS: [9, 9, 9, 9],
        S: [7, 7, 7, 7],
        A: [5, 5, 5, 5],
        B: [4, 4, 4, 4],
        'B-': [2, 2, 2, 2],
        C: [1, 1, 0, -1],
        D: [0, 0, -1, -2]
      }
    },
    {
      no: 5,
      label: ['スタッフ', 'ミドルスタッフ', 'リーダー', 'マネージャー', 'ゼネラルマネージャー'],
      labelSP: ['エキスパート', 'スペシャリスト'],
      point: [20, 40, 60, 80, 0],
      lub: [0, 0, 0, 0, 0],
      lvmax: [60, 60, 60, 60, 60],
      cop: [50, 50, 50, 50, 50],
      gousa: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
      raise: {
        SS: [9, 9, 9, 9, 9],
        S: [7, 7, 7, 7, 7],
        A: [5, 5, 5, 5, 5],
        B: [4, 4, 4, 4, 4],
        'B-': [2, 2, 2, 2, 2],
        C: [1, 1, 0, 0, -1],
        D: [0, 0, -1, -1, -2]
      }
    },
    {
      no: 6,
      label: ['スタッフ', 'ミドルスタッフ', 'シニアスタッフ', 'リーダー', 'マネージャー', 'ゼネラルマネージャー'],
      labelSP: ['エキスパート', 'スペシャリスト'],
      point: [20, 40, 40, 60, 80, 0],
      lub: [0, 0, 0, 0, 0, 0],
      lvmax: [60, 60, 60, 60, 60, 60],
      cop: [50, 50, 50, 50, 50, 50],
      gousa: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
      raise: {
        SS: [9, 9, 9, 9, 9, 9],
        S: [7, 7, 7, 7, 7, 7],
        A: [5, 5, 5, 5, 5, 5],
        B: [4, 4, 4, 4, 4, 4],
        'B-': [2, 2, 2, 2, 2, 2],
        C: [1, 1, 0, 0, 0, -1],
        D: [0, 0, -1, -1, -1, -2]
      }
    },
    {
      no: 7,
      label: ['スタッフ', 'ミドルスタッフ', 'シニアスタッフ', 'サブリーダー', 'リーダー', 'マネージャー', 'ゼネラルマネージャー'],
      labelSP: ['エキスパート', 'スペシャリスト'],
      point: [20, 40, 40, 40, 60, 80, 0],
      lub: [0, 0, 0, 0, 0, 0, 0],
      lvmax: [60, 60, 60, 60, 60, 60, 60],
      cop: [50, 50, 50, 50, 50, 50, 50],
      gousa: [[0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0], [0, 0]],
      raise: {
        SS: [9, 9, 9, 9, 9, 9, 9],
        S: [7, 7, 7, 7, 7, 7, 7],
        A: [5, 5, 5, 5, 5, 5, 5],
        B: [4, 4, 4, 4, 4, 4, 4],
        'B-': [2, 2, 2, 2, 2, 2, 2],
        C: [1, 1, 0, 0, -1, -1, -2],
        D: [0, 0, -1, -1, -2, -2, -3]
      }
    }
  ],
  professionals: {
    lv0: {
      label: 'スペシャリスト',
      allocate: 3,
      slogans: [
        {
          title: '定義',
          desc: '専門的知識及び相当の経験を有し、非定型的な業務や複雑な判断を必要とする業務を遂行する職位'
        }
      ]
    },
    lv1: {
      label: 'エキスパート',
      allocate: 4,
      slogans: [
        {
          title: '定義',
          desc: '高度な専門的知識と経験を駆使し、困難な技術的、専門的業務を遂行する。また企画判断や研究調査をなす職位'
        }
      ]
    }
  },
  rankDefs: [
    {
      rank: 'SS',
      label: 'SS',
      slogan: '期待を超えた顕著な成長と成果がみられた！',
      point: 20,
      score: 86
    },
    {
      rank: 'S',
      label: 'S',
      slogan: '期待を超えた成長と成果がみられた！',
      point: 15,
      score: 76
    },
    {
      rank: 'A',
      label: 'A',
      slogan: '期待以上の成長と成果がみられた！',
      point: 10,
      score: 66
    },
    {
      rank: 'B',
      label: 'B',
      slogan: '概ね期待に応える努力と成果がみられた！',
      point: 7.5,
      score: 56
    },
    {
      rank: 'B-',
      label: 'B-',
      slogan: '努力と成果はみられたが、幾分の改善の余地あり！',
      point: 5,
      score: 46
    },
    {
      rank: 'C',
      label: 'C',
      slogan: 'まだまだ改善と努力の余地がある！',
      point: 3,
      score: 36
    },
    {
      rank: 'D',
      label: 'D',
      slogan: 'さらなる改善と努力が必要！',
      point: 0,
      score: 0
    }
  ],
  evalDefs: [
    {
      genre: '業績成績評価',
      cats: [
        {
          cat: 'オペレーション',
          defs: [
            { id: 1, label: 'スピード', desc: '限られた期限、決められた時間内で手早く作業を行うこと' },
            { id: 2, label: 'タイムマネジメント', desc: '限られた時間内で最大の効果をあげるために優先順位をつけ、時間を有効かつ効率的に使うこと' },
            { id: 3, label: '進捗管理', desc: '業務の進行状況を定期的に確認し、必要な調整を行うこと' },
            { id: 4, label: '業務改善', desc: '既存の業務手順の見直しや効率化、改善策を提案・実施すること' },
            { id: 5, label: 'コスト意識', desc: '費用対効果を考えて業務を行うこと' },
            { id: 6, label: '実行力', desc: '計画を具体的な行動に移すこと' }
          ]
        },
        {
          cat: '品質',
          defs: [
            { id: 7, label: '正確性', desc: '決められた手順に従い、ミスなく作業を行うこと' },
            { id: 8, label: '品質管理', desc: '製品やサービスの品質を維持・向上させるための手順を適切に行っていること' },
            { id: 9, label: 'クオリティー追及', desc: '商品、サービスの質の向上のため工夫を継続的に取り組むこと' },
            { id: 10, label: '価値創造', desc: '新しい技術や製品サービスの開発、既存技術の改善や適用により具体的成果を出していること' }
          ]
        },
        {
          cat: '顧客対応・開拓',
          defs: [
            { id: 11, label: '顧客志向', desc: '顧客の立場に立って物事を考え、行動すること' },
            { id: 12, label: '顧客満足', desc: '既存の顧客に対して新サービスを提案し、顧客の利益の拡充を図ること' },
            { id: 13, label: '顧客維持', desc: '既存の顧客からの信頼を得て、緊密さを維持すること' },
            { id: 14, label: '新規開拓', desc: '新しい市場や顧客を開拓、獲得すること' }
          ]
        },
        {
          cat: 'マネジメント',
          defs: [
            { id: 15, label: '先見性', desc: '環境変化や経済動向を予測し、将来起こり得るであろうビジネスチャンスやリスクを想定していること' },
            { id: 16, label: '課題設定', desc: '目標を達成するうえで障害となる組織内または戦略上の課題を特定すること' },
            { id: 17, label: '組織運営', desc: '人材、戦略、リソースを最適に活用し、組織の目標達成を促進すること' },
            { id: 18, label: '計画・戦略立案', desc: ' 多角的な情報収集・分析を基に、戦略的な計画を立てること' }
          ]
        },
        {
          cat: 'リーダーシップ・部下育成',
          defs: [
            { id: 19, label: '理念・方針の共有', desc: '経営理念・方針をわかりやすく部下、後輩に理解させ、実行させること' },
            { id: 20, label: 'リーダーシップ', desc: '部下に助言を与え、必要な場合には自ら出向て問題解決に当たるなど、周囲の模範となっていること' },
            { id: 21, label: '部下育成', desc: '部下の成長と発展を支援し、彼らのスキルを向上させるための指導とメンターシップを提供すること' },
            { id: 22, label: '人物の評価', desc: '部下の能力、強み弱みを的確に把握し、公平に対応すること' },
            { id: 23, label: '採用と抜擢', desc: '最適な人材を採用し、適材適所で人材を配置すること' }
          ]
        },
        {
          cat: 'その他',
          defs: [
            { id: 101, label: 'その他', desc: '' }
          ]
        }
      ]
    },
    {
      genre: '姿勢態度評価',
      cats: [
        {
          cat: 'ビジネス資質',
          defs: [
            { id: 23, label: '積極性', desc: '新しい挑戦や取り組みを主体的に進める意欲や行動が伴っていること' },
            { id: 24, label: '達成意欲', desc: '目標を設定し、それを達成するための熱意や動機を持っていること' },
            { id: 25, label: '責任感', desc: 'やると決めたことはあきらめずに最後まで取り組み続けること' },
            { id: 26, label: '誠実性', desc: '人や物事に対して真摯かつ正直に取り組み、その行動や発言が信頼性や一貫性を持っていること' },
            { id: 27, label: 'ルール順守', desc: '法令、社内規則に従って行動できること' },
            { id: 28, label: '継続性', desc: '物事を成し遂げるまでコツコツやり続けること' },
            { id: 29, label: '自己管理', desc: '自身の業務、時間、ストレス、個人的な感情などを適切に管理し、調整できること' },
            { id: 30, label: '自己啓発', desc: '自己の成長と能力向上のために知識や技能の習得をすること' }
          ]
        },
        {
          cat: 'チーム・コミュニケーション',
          defs: [
            { id: 31, label: 'コミュニケーション', desc: '自ら進んでメンバーとの意思疎通を図ること' },
            { id: 32, label: '報連相', desc: '上司に対して報告・連絡・相談が取れていること' },
            { id: 33, label: 'チームワーク', desc: '円滑な業務遂行のために、メンバーと協力し合うこと' },
            { id: 34, label: 'ムードメーカー', desc: '本人の存在や言動がチームの意欲を向上させること' },
            { id: 35, label: '共感・思いやり', desc: '相手の立場や気持ちを理解し対処すること' },
            { id: 36, label: '情報の活用共有', desc: '有益な情報を適切に共有し、チーム全体のノウハウ向上を図ること' }
          ]
        },
        {
          cat: 'その他',
          defs: [
            { id: 102, label: 'その他', desc: '' }
          ]
        }
      ]
    },
    {
      genre: '能力スキル評価',
      cats: [
        {
          cat: 'ビジネススキル',
          defs: [
            { id: 37, label: 'ビジネスマナー', desc: 'ビジネスマンとして恥ずかしくない立ち居振る舞いをしていること' },
            { id: 38, label: '文章力', desc: '目的が相手に明瞭に伝わる文章の作成ができること' },
            { id: 39, label: 'プレゼンテーション力', desc: '伝えようとしている内容を、的確かつ説得力をもって表現すること' },
            { id: 40, label: '傾聴力', desc: 'タイミングよく質問、要約し、相手の立場に立って話を聞くこと' },
            { id: 41, label: '計数処理力', desc: '数値の意味することを即座に理解すること' },
            { id: 42, label: '理解力', desc: '上司からの指示や内容を的確に把握し、そこから最善の方法・手段を判断できること' },
            { id: 43, label: 'デジタルリテラシー', desc: 'デジタル技術やツールを利用して情報を検索、評価、作成、共有すること' },
            { id: 44, label: '職務知識', desc: '職務に関連する基礎的な技術、プロセス、知識を保有していること' }
          ]
        },
        {
          cat: '専門能力',
          defs: [
            { id: 45, label: '企画提案力', desc: '専門用語に頼ることなく、５W2Hをはっきりさせた提案を行うこと' },
            { id: 46, label: '交渉力', desc: '組織を代表して社外の人と接し、協力・理解を取り付けること' },
            { id: 47, label: '問題解決力', desc: '問題の原因を明らかにし、対策を講じること' },
            { id: 48, label: '決断力', desc: 'どんな状況、問題でも時機を逸することなく意識決定すること' },
            { id: 49, label: '分析力', desc: 'ひとつの事柄を複数の要素に分類し、その構成や特徴を明らかにすること' },
            { id: 50, label: '論理的思考力', desc: '情報を明確かつ理論的に分析し、その結果をもとに合理的な結論や解決策を導き出すこと' },
            { id: 51, label: '応用力', desc: '新しい問題に対して、創造的かつ効果的に柔軟な解決策を生み出すこと' },
            { id: 52, label: '専門知識', desc: '職務に関連する広く深い知識を保有し、業務に活用できていること' },
            { id: 53, label: 'イノベーション力', desc: '新しいアイディアや方法を持ち込み、その取り組みを通じて新しい価値を生み出すこと' }
          ]
        },
        {
          cat: 'その他',
          defs: [
            { id: 103, label: 'その他', desc: '' }
          ]
        }
      ]
    },
    {
      genre: '目標評価',
      cats: [
        {
          cat: '目標管理',
          defs: [
            { id: 104, label: '目標管理', desc: '' }
          ]
        }
      ]
    }
  ]
}

const getters = {
  slogans: (state) => state.slogans,
  slogansSP: (state) => state.slogansSP,
  professionals: (state) => state.professionals,
  classDefs: (state) => state.classDefs,
  rankDefs: (state) => state.rankDefs,
  evalDefs: (state) => state.evalDefs
}

const mutations = {
}

const actions = {
}

const Initial = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default Initial
